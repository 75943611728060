<!-- @Author: xiaoyaqu -->
<!-- @Date: 2019-08-26 19:21:35.799 -->
<!-- @Last Modified by: zhaoyang -->
<!-- @Last Modified time: 2023-11-27 11:43:42 -->

<template>
    <div class="borrow">
        <!-- banner -->
        <div class="borrow-banner">
            <div class="banner-main">
                <div class="ph-container">
                    <div
                        v-reach-show="'elegant-in'"
                        class="banner-content"
                    >
                        <div class="banner-text">
                            <div class="ph-title banner-title">
                                {{ $t('borrow.banner.title') }}
                            </div>
                            <div class="ph-context banner-context">
                                {{ $t('borrow.banner.text') }}
                            </div>
                            <div class="download-app">
                                <app-store-button
                                    type="apple"
                                    theme="dark"
                                />
                                <app-store-button
                                    type="google"
                                    theme="light"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Why billions trust MabilisCash? -->
        <!-- <borrow-card
            background-color="#F0F4F8"
            :background-image="whyBg"
        >
            <div class="why-content">
                <div class="why-text">
                    <div class="ph-title">
                        {{ $t('borrow.whyMab.title') }}
                    </div>
                    <div class="ph-context">
                        {{ $t('borrow.whyMab.text') }}
                    </div>
                </div>
                <div class="why-data">
                    <div
                        v-for="(item, index) in mabDatas"
                        :key="index"
                        class="data-item"
                        :class="{'data-item-active': currentWhyIndex === index}"
                        @mouseenter="currentWhyIndex = index"
                    >
                        <div class="item-number">
                            {{ item.number }}
                        </div>
                        <div class="item-desc">
                            {{ item.desc }}
                        </div>
                    </div>
                </div>
            </div>
        </borrow-card> -->
        <!-- Features of our products -->
        <borrow-card>
            <div class="feature-content">
                <div class="ph-title feature-title">
                    {{ $t('borrow.features.title') }}
                </div>
                <div class="feature-card">
                    <div
                        v-for="(item, index) in $t('borrow.features.card')"
                        :key="index"
                        class="feature-item"
                        :class="{'feature-item-active': currentFeatureIndex === index}"
                        @mouseenter="currentFeatureIndex = index"
                    >
                        <img
                            :src="featureImgList[index]"
                            class="item-icon"
                        >
                        <div class="item-left">
                            <div
                                class="item-title"
                                v-html="item.title"
                            />
                            <div class="item-desc">
                                {{ item.text }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </borrow-card>
        <!-- How to borrow? -->
        <borrow-card background-color="#F0F4F8">
            <div class="how-content">
                <div class="how-step">
                    <div class="ph-title">
                        {{ $t('borrow.process.title') }}
                    </div>
                    <div
                        v-for="(item, index) in $t('borrow.process.card')"
                        :key="index"
                        class="step-item"
                        :class="{'step-item-active': currentHowIndex === index}"
                        @mouseenter="handleClickHowIndex(index)"
                    >
                        <div class="step-number">
                            0{{ index + 1 }}
                        </div>
                        <div>{{ item }}</div>
                    </div>
                </div>
                <div class="how-img-wrap">
                    <img
                        class="how-img"
                        :src="howImgList[currentHowIndex]"
                    >
                </div>
            </div>
        </borrow-card>
        <!-- What our users say about us? -->
        <borrow-card
            background-color="#FFF"
            class="user-borrow-card"
        >
            <borrow-user class="home-user-content" />
        </borrow-card>
        <!-- Recently Added -->
        <borrow-card background-color="#F0F4F8">
            <borrow-activity />
        </borrow-card>
        <!-- Partners -->
        <borrow-card>
            <borrow-partners />
        </borrow-card>
    </div>
</template>
<script type="text/babel">
import prefetch from 'ssr-common/vue/mixin/server-prefetch';

import AppStoreButton from 'mabilis-cash/common/component/app-store-button';
import {downloadUrl} from 'mabilis-cash/common/constant/config';
import Api from 'mabilis-cash/common/resource/api';

import BorrowActivity from './component/borrow-activity.vue';
import BorrowCard from './component/borrow-card.vue';
import BorrowPartners from './component/borrow-partners.vue';
import BorrowUser from './component/borrow-user.vue';
import feature1 from './img/feature1.png';
import feature2 from './img/feature2.png';
import feature3 from './img/feature3.png';
import feature4 from './img/feature4.png';
import step1 from './img/step1.png';
import step2 from './img/step2.png';
import step3 from './img/step3.png';
import step4 from './img/step4.png';
import step5 from './img/step5.png';
import whyBg from './img/why-bg.png';
export default {
    name: 'Borrow',

    components: {
        BorrowCard,
        BorrowUser,
        BorrowActivity,
        BorrowPartners,
        AppStoreButton
    },

    title() {
        return 'MabilisCash - Fast Online Cash Loan APP | Urgent Loan In Philippines';
    },

    mixins: [prefetch('home')],

    data() {
        return {
            whyBg,
            currentWhyIndex: 0,
            featureImgList: [feature1, feature2, feature3, feature4],
            currentFeatureIndex: '',
            howImgList: [step1, step2, step3, step4, step5],
            currentHowIndex: 0
        };
    },

    head() {
        const keywords = 'quick loans, loans, online personal loan, online lending, loan, loan companies, payday loan';
        const description = 'Mabilis Cash is an Internet lending platform in the Philippines that provides fast, convenient and secure financial services for our customers.';

        return {
            meta: [
                {name: 'keywords', content: keywords},
                {name: 'description', content: description}
            ],
            link: [
                {rel: 'canonical', href: 'https://www.mabiliscash.ph/borrow'}
            ]
        };
    },

    computed: {
        mabDatas() {
            return [
                {number: this.home?.operationData?.totalDownload?.toLocaleString() || '', desc: 'Total App Downloads'},
                {number: this.home?.operationData?.totalRegister?.toLocaleString() || '', desc: 'Total Registers'},
                {number: this.home?.operationData?.approvedTransaction?.toLocaleString() || '', desc: 'Approved Transactions'}
            ];
        }
    },

    mounted() {
        this.timer = setInterval(() => {
            this.currentHowIndex = (this.currentHowIndex + 1) % 5;
        }, 4000);
    },

    beforeDestroy() {
        clearInterval(this.timer);
    },

    methods: {
        downloadAndroid() {
            window.open(downloadUrl);
        },

        handleClickHowIndex(index) {
            this.currentHowIndex = index;
            clearInterval(this.timer);
            this.timer = setInterval(() => {
                this.currentHowIndex = (this.currentHowIndex + 1) % 5;
            }, 4000);
        },

        async initFetch() {
            const {data: {body}} = await Api.businessNumber({hideLoading: true});

            return {operationData: body};
        },
    }
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
@import "~mabilis-cash/common/style/index";

.borrow {
    .borrow-banner {
        position: relative;
        overflow: hidden;
        background: linear-gradient(90deg, #e6eaff 0%, #d9ddef 100%);
        margin-top: -80px;

        @media screen and (max-width: $screen-md) {
            margin-top: -50px;
        }

        @media screen and (min-width: ($screen-edition + 1)) {
            background: #e9edff;
        }

        .banner-main {
            width: 100%;
            padding: 215px 0 235px;
            background-image: url("./img/banner.png");
            background-size: contain;
            background-position: right top;
            background-repeat: no-repeat;

            @media screen and (max-width: $screen-md) {
                background-image: url("./img/banner-m.png");
                padding: 90px 0 338px;
            }
        }

        .banner-content {
            display: flex;
            justify-content: space-between;
            position: relative;

            .banner-text {
                width: 56.37%;

                .banner-title {
                    max-width: 100%;
                    font-size: 60px;
                    line-height: 85px;
                }

                .download-app {
                    margin-top: 40px;
                    display: flex;

                    @media screen and (max-width: $screen-md) {
                        justify-content: center;
                    }
                }

                @media screen and (max-width: $screen-md) {
                    width: 100%;

                    .banner-title {
                        font-size: 26px;
                        line-height: normal;
                        text-align: center;
                    }

                    .banner-context {
                        text-align: center;
                        font-size: 12px;
                    }

                    .download-app {
                        margin-top: 20px;
                    }
                }
            }

            .banner-bg {
                position: absolute;
                width: auto;
                height: 490px;
                top: -110px;
                right: -73px;

                @media screen and (max-width: $screen-md) {
                    height: 265px;
                    top: 0;
                    left: -20px;
                    right: 0;
                }
            }
        }
    }

    .why-content {
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: $screen-md) {
            flex-direction: column;
        }

        .why-text {
            width: 480px;

            @media screen and (max-width: $screen-md) {
                width: 100%;
            }
        }

        .why-data {
            position: relative;
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;

            .data-item {
                width: 280px;
                height: 180px;
                padding: 30px;
                border-radius: 32px;
                border: 2px solid rgba(255, 255, 255, 0);
                background: linear-gradient(150deg, rgba(255, 255, 255, 0.5) -10.11%, rgba(255, 255, 255, 0.5) -10.1%, $base-white 109.47%);
                backdrop-filter: blur(20px);
                display: flex;
                justify-content: center;
                flex-direction: column;
                cursor: pointer;

                &:first-child {
                    margin-right: 20px;
                    margin-top: -10px;
                }

                &:last-child {
                    margin-top: 20px;
                    margin-right: 180px;
                }

                .item-number {
                    color: $primary-blue;
                    font-size: 32px;
                    font-weight: 800;
                    letter-spacing: -0.32px;
                    margin-bottom: 5px;
                }

                .item-desc {
                    color: $base-black;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                }

                &-active {
                    border: none;
                    background: $primary-gradient;
                    box-shadow: 0 30px 60px 0 rgba(5, 15, 52, 0.2);

                    .item-number,
                    .item-desc {
                        color: $base-white;
                    }
                }

                @media screen and (max-width: $screen-md) {
                    width: 100%;
                    height: 110px;
                    padding: 20px 30px;
                    border-radius: 20px;
                    margin-top: 10px;

                    &:first-child {
                        margin-right: 0;
                        margin-top: 20px;
                    }

                    &:last-child {
                        margin-top: 10px;
                        margin-right: 0;
                    }

                    .item-number {
                        color: $primary-blue;
                        font-size: 26px;
                        font-weight: 800;
                        letter-spacing: -0.26px;
                        margin-bottom: 8px;
                    }

                    .item-desc {
                        color: $base-black;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                    }

                    &-active {
                        border: none;
                        background: $primary-gradient;
                        box-shadow: 0 40px 40px 0 rgba(5, 15, 52, 0.2);

                        .item-number,
                        .item-desc {
                            color: $base-white;
                        }
                    }
                }
            }
        }
    }

    .feature-content {
        .feature-title {
            max-width: 100%;
            margin-bottom: 40px;

            @media screen and (max-width: $screen-md) {
                margin-bottom: 20px;
            }
        }

        .feature-card {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;

            .feature-item {
                width: calc(50% - 20px);
                height: 165px;
                padding: 0 15px;
                display: flex;
                align-items: center;

                .item-title {
                    color: $base-black;
                    font-size: 18px;
                    font-weight: 800;
                    line-height: 24px;
                }

                .item-desc {
                    color: $base-black;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 20px;
                    margin-top: 15px;
                }

                .item-icon {
                    width: 165px;
                    height: 165px;
                }

                &:first-child,
                &:nth-child(2) {
                    margin-bottom: 20px;
                }

                &-active {
                    background: $base-white;
                    box-shadow: 0 15px 40px rgba(96, 97, 112, 0.15);
                    border-radius: 30px;
                }

                @media screen and (max-width: $screen-md) {
                    width: 100%;
                    height: fit-content;
                    padding: 5px 15px 5px 5px;
                    border-radius: 20px;
                    margin-bottom: 10px;
                    position: relative;
                    background: $base-white;
                    box-shadow: 0 15px 50px rgba(215, 228, 249, 0.5);

                    .item-left {
                        width: 100%;
                    }

                    .item-title {
                        font-size: 14px;
                        line-height: normal;
                    }

                    .item-desc {
                        margin-top: 8px;
                        font-size: 12px;
                        line-height: normal;
                        color: #7e7e8a;
                    }

                    .item-icon {
                        width: 90px;
                        height: 90px;
                    }

                    &:first-child,
                    &:nth-child(2) {
                        margin-bottom: 10px;
                    }

                    &-active {
                        box-shadow: 0 15px 50px rgba(215, 228, 249, 0.5);
                        border-radius: 20px;
                    }
                }
            }
        }
    }

    .how-content {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .how-step {
            .step-item {
                margin-top: 40px;
                color: $base-black;
                font-size: 24px;
                font-weight: 400;
                line-height: 160%;
                display: flex;
                align-items: center;
                cursor: pointer;

                .step-number {
                    width: 38px;
                    height: 38px;
                    border-radius: 50%;
                    border: 1px solid $base-black;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $base-black;
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 160%;
                    margin-right: 20px;
                    flex-shrink: 0;
                }

                &-active {
                    color: $primary-purple;
                    font-weight: 800;

                    .step-number {
                        border: none;
                        color: $base-white;
                        background-color: $primary-purple;
                        font-weight: 800;
                    }
                }

                @media screen and (max-width: $screen-md) {
                    margin-top: 30px;
                    font-size: 16px;
                    line-height: normal;

                    .step-number {
                        font-size: 16px;
                        line-height: normal;
                        margin-right: 10px;
                    }
                }
            }
        }

        .how-img-wrap {
            .how-img {
                width: 420px;
                height: auto;
                flex-shrink: 0;
            }

            @media screen and (max-width: $screen-md) {
                display: none;
            }
        }
    }

    .user-borrow-card {
        padding-bottom: 80px;

        ::v-deep .ph-container {
            width: 100%;
            max-width: unset;
        }

        .home-user-content {
            position: relative;
        }

        @media screen and (max-width: $screen-md) {
            padding-bottom: 40px;
        }
    }
}
</style>
