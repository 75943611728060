<!-- @Author: wuhuiwang -->
<!-- @Date: 2023-12-28 15:11:38 -->
<!-- @Last Modified by: wangwuhui -->
<!-- @Last Modified time: 2023-12-29 15:46:59 -->

<template>
    <div v-if="mounted">
        <div class="home-user-pc  d-none d-md-block">
            <div class="ph-title slide-title">
                <div>{{ getI18n('title') }}</div>
            </div>
            <swiper
                ref="mySwiper"
                :options="swiperOptions"
            >
                <swiper-slide
                    v-for="(item, index) in userList"
                    :key="index"
                >
                    <div
                        class="slide-box"
                        :style="{'background-image': `url(${item.bgUrl})`}"
                    >
                        <div class="slide">
                            <div class="left">
                                <img
                                    class="left-img"
                                    :src="item.icon"
                                >
                            </div>
                            <div class="right">
                                <div class="bottom-box">
                                    <div class="bottom-title-box">
                                        <div class="bottom-title">
                                            {{ item.name }}
                                        </div>
                                        <img
                                            :src="item.sex"
                                            class="bottom-sex"
                                        >
                                    </div>
                                    <div class="bottom-content">
                                        {{ item.said }}
                                    </div>
                                    <img
                                        src="../img/star1.png"
                                        class="bottom-star"
                                    >
                                </div>
                                <div class="bottom-box-two" />
                                <div class="bottom-box-three" />
                            </div>
                        </div>
                    </div>
                </swiper-slide>
                <!-- <div
                    slot="pagination"
                    class="swiper-pagination"
                /> -->
            </swiper>
            <div class="container-box">
                <div class="container-wrap">
                    <img
                        src="../img/left-arrow.svg"
                        class="swiper-button-prev-self prev-self"
                    >
                    <img
                        class="swiper-button-next-self next-self"
                        src="../img/right-arrow.svg"
                    >
                </div>
            </div>
        </div>
        <div class="home-user-mobile d-block d-md-none">
            <div class="slide-title">
                {{ getI18n('title') }}
            </div>
            <swiper
                ref="mySwiper"
                :options="swiperOptions"
            >
                <swiper-slide
                    v-for="(item, index) in userList"
                    :key="index"
                >
                    <div
                        class="slide-box"
                        :style="{'background-image': `url(${item.mBgUrl})`}"
                    >
                        <div class="bottom-box">
                            <div class="bottom-title-box">
                                <img
                                    :src="item.icon"
                                    class="bottom-img"
                                >
                                <div class="bottom-title">
                                    <div>
                                        {{ item.name }}
                                    </div>
                                    <img
                                        src="../img/star1.png"
                                        class="bottom-star"
                                    >
                                </div>
                            </div>
                            <div class="bottom-content">
                                {{ item.said }}
                            </div>
                        </div>
                        <div class="bottom-box-two" />
                        <div class="bottom-box-three" />
                    </div>
                </swiper-slide>
                <div
                    slot="pagination"
                    class="swiper-pagination"
                />
            </swiper>
        </div>
    </div>
</template>

<script>
import 'swiper/css/swiper.css';
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';

import female from '../img/female.png';
import male from '../img/male.png';
import star1 from '../img/star1.png';
import userBg1 from '../img/user-bg1.png';
import userBg2 from '../img/user-bg2.png';
import userBg3 from '../img/user-bg3.png';
import userBg4 from '../img/user-bg4.png';
import userBg5 from '../img/user-bg5.png';
import userBgm1 from '../img/user-bgm1.png';
import userBgm2 from '../img/user-bgm2.png';
import userBgm3 from '../img/user-bgm3.png';
import userBgm4 from '../img/user-bgm4.png';
import userBgm5 from '../img/user-bgm5.png';
import User1 from '../img/user1.png';
import User2 from '../img/user2.png';
import User3 from '../img/user3.png';
import User4 from '../img/user4.png';
import User5 from '../img/user5.png';

export default {
    name: 'BorrowUser',

    components: {
        Swiper,
        SwiperSlide
    },

    data() {
        return {
            mounted: false,
            swiperOptions: {
                autoplay: {
                    delay: 4000,
                    disableOnInteraction: false
                },
                loop: true,
                pagination: {
                    bulletActiveClass: 'my-bullet-active',
                    el: '.swiper-pagination'
                },
                navigation: {
                    nextEl: '.swiper-button-next-self',
                    prevEl: '.swiper-button-prev-self'
                }
            },
            male,
            female
        };
    },

    computed: {
        swiper() {
            return this.$refs.mySwiper.$swiper;
        },

        userList() {
            const UserImg = [User1, User2, User3, User4, User5];
            const bgImg = [userBg1, userBg2, userBg3, userBg4, userBg5];
            const mBgImg = [userBgm1, userBgm2, userBgm3, userBgm4, userBgm5];
            const {getI18n} = this;

            return getI18n('userList').map((item, idx) => ({
                ...item,
                icon: UserImg[idx],
                sex: item.sex === 'male' ? male : female,
                bgUrl: bgImg[idx],
                mBgUrl: mBgImg[idx]
            }));
        }
    },

    mounted() {
        this.mounted = true;
    },

    methods: {
        getI18n(key, params) {
            return this.$t(`borrow.homeUser.${key}`, params);
        }
    }
};
</script>

<style lang="scss">
@import "~easycash/common/style/style";

.home-user-pc,
.home-user-mobile {
    .swiper-slide {
        transform: translate3d(0, 0, 0);
    }

    .swiper-pagination-bullet {
        background-color: $base-black;
        opacity: 10%;
    }

    .swiper-pagination-bullets {
        bottom: 60px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: $screen-md) {
            bottom: 40px;
        }

        .swiper-pagination-bullet {
            width: 8px;
            height: 8px;
        }

        .my-bullet-active {
            width: 12px;
            height: 12px;
            background-color: #f0f4f8;
            opacity: 100%;
        }
    }
}

.home-user-mobile {
    .swiper-pagination-bullets {
        .my-bullet-active {
            background-color: $base-black;
        }
    }

    .swiper-container {
        padding-bottom: 84px;
    }
}
</style>

<style lang="scss" scoped>
@import "~mabilis-cash/common/style/index";

.home-user-pc {
    min-height: 500px;

    .slide-title {
        max-width: 100%;
        margin: 0 auto;
        width: calc(100% - 200px);
        max-width: 1240px;

        @media screen and (max-width: $screen-md) {
            width: calc(100% - 40px);
        }
    }

    .slide-box {
        width: 100%;
        padding-bottom: 100px;
        background-size: cover;
        background-position: center top;
        background-repeat: no-repeat;
        background-image: url("../img/user-bg1.png");

        .slide {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 40px;

            .left {
                width: 360px;
                height: 360px;
                border-radius: 50%;
                overflow: hidden;
                position: relative;

                .left-img {
                    z-index: 1;
                    position: relative;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .right {
                width: 570px;
                margin-left: 20px;
                position: relative;

                .top-title {
                    color: $base-white;
                    font-size: 46px;
                    font-weight: 800;
                    line-height: 130%;
                }

                .bottom-box {
                    min-height: 280px;
                    color: $base-black;
                    box-sizing: border-box;
                    padding: 40px 50px 64px;
                    border-radius: 32px;
                    background: $base-white;
                    box-shadow: 0 15px 50px 0 rgba(215, 228, 249, 50%);
                    position: relative;
                    z-index: 9;

                    .bottom-title-box {
                        display: flex;
                        align-items: center;

                        .bottom-title {
                            font-size: 24px;
                            font-weight: 800;
                            line-height: 1.45;
                            margin-right: 10px;
                        }

                        .bottom-sex {
                            width: 28px;
                            height: 28px;
                        }
                    }

                    .bottom-content {
                        margin-top: 20px;
                        font-size: 16px;
                        font-weight: 300;
                        line-height: 24px;
                        color: #7e7e8a;
                        letter-spacing: -0.4px;
                        font-style: italic;
                    }

                    .bottom-star {
                        width: 122px;
                        height: auto;
                        position: absolute;
                        right: 60px;
                        bottom: 34px;
                    }

                    &-two {
                        width: 540px;
                        height: 240px;
                        border-radius: 32px;
                        opacity: 80%;
                        background: $base-white;
                        box-shadow: 0 15px 50px 0 rgba(215, 228, 249, 50%);
                        position: absolute;
                        right: -20px;
                        bottom: 20px;
                        z-index: 5;
                    }

                    &-three {
                        width: 500px;
                        height: 200px;
                        border-radius: 32px;
                        opacity: 60%;
                        background: $base-white;
                        box-shadow: 0 15px 50px 0 rgba(215, 228, 249, 50%);
                        position: absolute;
                        right: -40px;
                        bottom: 40px;
                        z-index: 2;
                    }
                }
            }
        }
    }

    .container-box {
        z-index: 1;
        position: absolute;
        height: 100%;
        left: 0;
        right: 0;
        top: 0;
        margin: 0 auto;
        width: calc(100% - 200px);
        max-width: 1240px;

        @media screen and (max-width: $screen-md) {
            width: calc(100% - 40px);
        }

        .container-wrap {
            height: 100%;
            position: relative;

            .swiper-button-prev-self,
            .swiper-button-next-self {
                width: 56px;
                height: 56px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                opacity: 50%;
                cursor: pointer;

                &:hover {
                    opacity: 100%;
                    border-radius: 50%;
                    box-shadow: 0 10px 40px rgba(0, 0, 0, 10%);
                }
            }

            .prev-self {
                left: 0;
            }

            .next-self {
                right: 0;
            }
        }
    }
}

.home-user-mobile {
    overflow: hidden;

    .slide-title {
        width: 250px;
        color: $base-black;
        font-size: 24px;
        font-weight: 800;
        line-height: normal;
        margin: 0 0 0 20px;
    }

    .slide-box {
        box-sizing: border-box;
        padding: 20px 20px 50px;
        width: 100%;
        position: relative;
        background-size: cover;
        background-position: center top;
        background-repeat: no-repeat;
        background-image: url("../img/user-bgm1.png");

        .bottom-box {
            position: relative;
            color: $base-black;
            box-sizing: border-box;
            min-height: 210px;
            padding: 10px 20px;
            border-radius: 20px;
            background: $base-white;
            box-shadow: 0 15px 50px 0 rgba(215, 228, 249, 50%);
            z-index: 9;

            .bottom-title-box {
                display: flex;
                align-items: center;

                .bottom-img {
                    min-width: 70px;
                    min-height: 70px;
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                }

                .bottom-title {
                    font-size: 18px;
                    font-weight: 800;
                    line-height: normal;
                    margin-left: 15px;

                    .bottom-star {
                        width: 83.5px;
                        height: auto;
                        margin-top: 8px;
                        margin-left: -5px;
                    }
                }
            }

            &-two {
                width: calc(100% - 60px);
                height: 140px;
                border-radius: 20px;
                opacity: 80%;
                background: $base-white;
                box-shadow: 0 15px 50px 0 rgba(215, 228, 249, 50%);
                position: absolute;
                left: 0;
                right: 0;
                bottom: 35px;
                z-index: 5;
                margin: 0 auto;
            }

            &-three {
                width: calc(100% - 80px);
                height: 200px;
                border-radius: 32px;
                opacity: 60%;
                background: $base-white;
                box-shadow: 0 15px 50px 0 rgba(215, 228, 249, 50%);
                position: absolute;
                left: 0;
                right: 0;
                bottom: 20px;
                z-index: 2;
                margin: 0 auto;
            }

            .bottom-content {
                margin-top: 15px;
                color: #7e7e8a;
                font-size: 12px;
                font-style: italic;
                font-weight: 300;
                line-height: 18px;
                letter-spacing: -0.4px;
            }
        }
    }
}
</style>
