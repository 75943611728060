/*
 * @Author: zhaoyang
 * @Date: 2022-09-15 19:54:31
 * @Last Modified by: wuhuiwang
 * @Last Modified time: 2024-05-Th 10:56:07
 */

import Resource from '@yqg/resource';

import {getEurekaHost} from 'mabilis-cash/common/constant/config';

const api = {
    getHost: getEurekaHost,

    generalConfig: {
        url: '/api/generalConfig',
        method: 'get'
    },

    getChannel: {
        url: '/api/phi/repay/channel',
        method: 'get'
    },

    repayWithChannel: {
        url: '/api/phi/repayWithChannel',
        method: 'post'
    },

    uploadSmsInfoV3: {
        url: '/api/loan/uploadSmsInfoV3',
        method: 'post'
    },

    sendVerificationWithoutCaptcha: {
        url: '/api/mobile/sendVerificationWithoutCaptcha',
        method: 'post'
    },

    registerOrLogin: {
        url: '/api/v2/user/union/registerOrLogin',
        method: 'post'
    },

    findByH5Code: {
        url: '/api/cashloan/sea/landingPage/findByH5Code',
        method: 'get'
    },

    uploadMetaEvent: {
        url: '/api/sea/uploadMetaEvent',
        method: 'post'
    },

    generateCaptcha: {
        url: '/api/user/generateCaptcha',
        method: 'post'
    },

    getCaptchaImage: {
        url: '/api/user/getCaptchaImage/:captchaKey',
        method: 'get',
        link: true
    },
    businessNumber: {
        url: '/api/official/website/businessNumber',
        method: 'get',
    },

    uploadIdCardImage: {
        url: '/api/loan/phi/uploadIdCardImage',
        method: 'post',
        formData: true
    },

    uploadFile: {
        url: '/api/loan/seaExtra/uploadFile',
        method: 'post',
        formData: true
    },

    uploadPdfFile: {
        url: '/api/loan/seaExtra/uploadPdfFile',
        method: 'post',
        formData: true
    },

    fetchUser: {
        url: '/api/v2/user/fetch',
        method: 'get'
    },
};

export default Resource.create(api);
