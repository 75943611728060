<!-- @Author: wuhuiwang -->
<!-- @Date: 2023-12-26 11:19:34 -->
<!-- @Last Modified by: wangwuhui -->
<!-- @Last Modified time: 2023-12-28 14:36:27 -->

<template>
    <div
        class="borrow-card"
        :style="{
            'background-color': backgroundColor,
            'background-image': `url(${backgroundImage})`
        }"
    >
        <div
            v-reach-show="'elegant-in'"
            class="ph-container"
        >
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'BorrowCard',

    props: {
        backgroundColor: {
            type: String,
            default: '#FFF'
        },

        backgroundImage: {
            default: ''
        }
    }
};
</script>

<style lang="scss" scoped>
@import "~mabilis-cash/common/style/index";

.borrow-card {
    width: 100%;
    padding: 100px 0 180px;
    border-radius: 80px 80px 0 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: -80px;
    position: relative;

    @media screen and (max-width: $screen-md) {
        padding: 40px 0 80px;
        border-radius: 40px 40px 0 0;
        margin-top: -40px;
        background-image: none !important;
    }
}
</style>
