var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "borrow-card",
      style: {
        "background-color": _vm.backgroundColor,
        "background-image": "url(" + _vm.backgroundImage + ")",
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "reach-show",
              rawName: "v-reach-show",
              value: "elegant-in",
              expression: "'elegant-in'",
            },
          ],
          staticClass: "ph-container",
        },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }